// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// You can't selectively load only the pieces you need with require but with imports, you can selectively load only the pieces you need. That can save memory.
// Loading is synchronous (step by step) for require on the other hand import can be asynchronous (without waiting for previous import) so it can perform a little better than require

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// need for Boostrap JS to work
// see https://gist.github.com/maxivak/2612fa987b9f9ed7cb53a88fcba247b3#jquery-jquery-ui
import $ from 'jquery';
global.$ = $ 
global.jQuery = $

require("jquery-ui")

require("leaflet")
require("leaflet-gpx")
require("@raruto/leaflet-elevation")
require("leaflet-loading")

require("src/cocoon")

import 'bootstrap'
import 'jquery-ui-dist/jquery-ui.min.css'

import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import * as L from 'leaflet'
import 'leaflet-defaulticon-compatibility'
import '@raruto/leaflet-elevation/dist/leaflet-elevation.min.css'
import 'leaflet-loading/src/Control.Loading.css'

import "@fortawesome/fontawesome-free/js/all"
import './src/application.scss'

import * as d3 from "d3"

// togeojson should be in global scope for leaflet-filelayer to work
import * as togeojson from '@mapbox/togeojson'
// doing the same for togpx
import * as togpx from 'togpx'
// exposing it, because it's used by the user
window.togpx = togpx
// see https://github.com/makinacorpus/Leaflet.FileLayer/issues/60
import fileLayer from 'leaflet-filelayer'
fileLayer(null, L, togeojson)

// not working when this line is set in packs/intro.js
import 'intro.js/introjs.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
